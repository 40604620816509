import React, { createContext, useState } from 'react'
import { AuthContext } from './auth_context';
import { app } from "../firebase"
import _ from "lodash";
import { config } from 'Constants'
import { parseDateMonthYear } from 'helpers/date_helpers';
import { getFirestore, collection, doc, updateDoc, deleteDoc } from "firebase/firestore";

export const UserContext = createContext();

export const UserContextProvider = (props) => {
    const { currentUser } = React.useContext(AuthContext)
    const [userObject, setUserObject] = useState(config.emptyUserObject);
    const userObjectRef = React.useRef({});
    var usersRef = collection(getFirestore(app), 'users')

    userObjectRef.current = userObject;


    function saveUserObject(userObject) {
        let userObjectClone = _.cloneDeep(userObject)
        if (userObject.loaded && currentUser) {
            try {

                updateDoc(doc(usersRef, currentUser.uid), userObjectClone);
            } catch (error) {
                console.error("Error updating document:", error);
            }

        }

        setUserObject(userObjectClone)

    }

    function setFoodVendor(foodVendorName, foodVendorID, foodVendorDeliveryRate) {
        saveUserObject({ ...userObject, foodVendor: foodVendorName, foodVendorID: foodVendorID, foodVendorDeliveryRate: foodVendorDeliveryRate })
    }

    function setChosenDeliveryDate(chosenDeliveryDate) {
        saveUserObject({ ...userObjectRef.current, chosenDeliveryDate: chosenDeliveryDate })
    }


    function getCalorieCategory() {
        if (userObject.isUsingMacros) {
            const calculatedCalories = userObject.proteins * 4 + userObject.fats * 9 + userObject.carbs * 4
            return Math.round(calculatedCalories / 100) * 100
        }
        return Math.round(userObject.calories / 100) * 100
    }


    function getCalories() {
        if (userObject.isUsingMacros) {
            const calculatedCalories = userObject.proteins * 4 + userObject.fats * 9 + userObject.carbs * 4
            return calculatedCalories
        }
        return userObject.calories
    }

    function getCustomRecipeBookId() {
        for (let recipeBookId of userObject.recipeBooks) {
            if (recipeBookId !== config.baseRecipeBookId) { return recipeBookId }
        }
    }

    function resetUserObject() {
        //only should be used during logout
        setUserObject(config.emptyUserObject)

    }

    function getCurrentWeight() {
        let weight = ''
        let unit = ''
        if (userObject.weight) {
            let weightKeys = Object.keys(userObject.weight)
            if (weightKeys.length !== 0) {
                let maxDate = parseDateMonthYear(weightKeys[0])

                weight = userObject.weight[weightKeys[0]]
                unit = "lbs"
                //userObject.weight[weightKeys[0]] can be either a float straight up in pounds or {value:<Float>, unit:<String>}
                if (userObject.weight[weightKeys[0]].value) {
                    weight = userObject.weight[weightKeys[0]].value
                    unit = userObject.weight[weightKeys[0]].unit
                }
                for (let weightDate of Object.keys(userObject.weight)) {
                    let currentDate = parseDateMonthYear(weightDate)
                    if (currentDate > maxDate) {
                        weight = userObject.weight[weightDate]
                        unit = "lbs"
                        if (userObject.weight[weightDate].value) {
                            weight = userObject.weight[weightDate].value
                            unit = userObject.weight[weightDate].unit
                        }
                        maxDate = currentDate
                    }
                }
            }
        }
        return [weight, unit]
    }

    function getCurrentBodyFat() {
        let bodyFat = ''
        if (userObject.bodyFat) {
            let bodyFatKeys = Object.keys(userObject.bodyFat)
            if (bodyFatKeys.length !== 0) {
                let maxDate = parseDateMonthYear(bodyFatKeys[0])

                if (userObject.bodyFat[bodyFatKeys[0]]) {
                    bodyFat = userObject.bodyFat[bodyFatKeys[0]]
                }
                //userObject.weight[weightKeys[0]] can be either a float straight up in pounds or {value:<Float>, unit:<String>}

                for (let weightDate of Object.keys(userObject.bodyFat)) {
                    let currentDate = parseDateMonthYear(weightDate)
                    if (currentDate > maxDate) {
                        bodyFat = userObject.bodyFat[weightDate]

                        maxDate = currentDate
                    }
                }
            }
        }
        return bodyFat
    }

    async function deleteUserInDB() {
        try {
            await deleteDoc(doc(usersRef, currentUser.uid));
            console.log("Document successfully deleted!");
        } catch (error) {
            console.error("Error deleting document:", error);
        } resetUserObject()
    }


    return (
        <UserContext.Provider value={{ userObject: userObject, userObjectRef: userObjectRef, getCalories: getCalories, setUserObject: saveUserObject, setUserObjectNoSave: setUserObject, getCalorieCategory: getCalorieCategory, setFoodVendor: setFoodVendor, setChosenDeliveryDate: setChosenDeliveryDate, resetUserObject: resetUserObject, getCustomRecipeBookId: getCustomRecipeBookId, deleteUserInDB: deleteUserInDB, getCurrentWeight: getCurrentWeight, getCurrentBodyFat: getCurrentBodyFat }}>
            {props.children}
        </UserContext.Provider>

    );
};