import './calorie_calculator.css';
import React from "react";
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { UserContext } from 'contexts/user_context';
import { Button, FormControl, InputLabel } from '@material-ui/core';
import { getDateMonthYear } from 'helpers/date_helpers';

export const activityMultiplierList = [
    { name: "Sedentary", value: 1.2 },
    { name: "Lightly active", value: 1.3 },
    { name: "Moderately active", value: 1.4 },
    { name: "Very active", value: 1.5 }
];

export const exerciseMultiplierList = [
    { name: "Little or no exercise", value: 1.0 },
    { name: "Light exercise (1-3 days/week)", value: 1.1 },
    { name: "Moderate exercise (3-5 days/week)", value: 1.2 },
    { name: "Heavy exercise (6-7 days/week)", value: 1.3 },
    { name: "Very heavy exercise (twice a day)", value: 1.4 }
];

const genderList = [
    { name: "Female", value: "female" },
    { name: "Male", value: "male" }
];

export const goalMultiplierList = [
    { name: "Lose weight", value: 0.80 },
    { name: "Maintain weight", value: 1 },
    { name: "Build Muscle", value: 1.08 }
];

const macroBreakdownList = [
    { name: "Macro-balanced (30% protein, 40% carbs, 30% fat)", value: { protein: 0.3, carbs: 0.4, fat: 0.3 } },
    { name: "Low-carb (40% protein, 20% carbs, 40% fat)", value: { protein: 0.4, carbs: 0.2, fat: 0.4 } },
    { name: "Keto (20% protein, 5% carbohydrates, 75% fat)", value: { protein: 0.2, carbs: 0.05, fat: 0.75 } },
    { name: "High-carb (20% protein, 50% carbs, 30% fat)", value: { protein: 0.2, carbs: 0.5, fat: 0.3 } }
];

const CalorieCalculator = (props) => {
    const userContext = React.useContext(UserContext);
    const { userObject, setUserObject, getCurrentWeight, getCurrentBodyFat } = userContext;
    const [step, setStep] = React.useState(1);
    const [weightUnit, setWeightUnit] = React.useState(getCurrentWeight()[1] === '' ? "lbs" : getCurrentWeight()[1]);
    const [ageError, setAgeError] = React.useState(false);

    const isStep1Valid = () => {
        const age = parseInt(userObject.age, 10);
        const isValidAge = userObject.age === '' || (age && age >= 18); // Validation without state update
        return userObject.sex && isValidAge && (userObject.height || (userObject.heightFeet && userObject.heightInches)) && getCurrentWeight()[0];
    };

    const isStep2Valid = () => {
        return userObject.goalMultiplier && userObject.activityMultiplier && userObject.exerciseMultiplier && userObject.macroBreakdownName;
    };

    const handleWeightChange = (e) => {
        let weightObject = userObject.weight ? userObject.weight : {};
        let dateMonthYear = getDateMonthYear(new Date());
        weightObject[dateMonthYear] = { unit: weightUnit, value: parseFloat(e.target.value) };
        setUserObject({ ...userObject, weight: weightObject });
    };

    const handleBodyFatChange = (e) => {
        let bodyFatObject = userObject.bodyFat ? userObject.bodyFat : {};
        console.log(bodyFatObject)
        let dateMonthYear = getDateMonthYear(new Date());
        bodyFatObject[dateMonthYear] = parseFloat(e.target.value);
        setUserObject({ ...userObject, bodyFat: bodyFatObject });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'age') {
            const age = parseInt(value, 10);
            setAgeError(value !== '' && (!age || age < 18)); // Set error state if invalid age
        }

        if (name === 'macroBreakdownName') {
            const selectedMacro = macroBreakdownList.find(macro => macro.name === value);
            setUserObject({ ...userObject, macroBreakdownName: value, macroBreakdown: selectedMacro.value });
        } else {
            setUserObject({ ...userObject, [name]: value });
        }
    };

    const handleWeightUnitChange = (e) => {
        setWeightUnit(e.target.value);
        let weightObject = userObject.weight;
        if (userObject.weight) {
            let dateMonthYear = getDateMonthYear(new Date());
            if (weightObject[dateMonthYear]) {
                weightObject[dateMonthYear] = { ...weightObject[dateMonthYear], unit: e.target.value };
            }
        }
        setUserObject({ ...userObject, weight: weightObject });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        let heightCentimeters = userObject.heightUnit === 'cm'
            ? parseFloat(userObject.height)
            : (((parseInt(userObject.heightFeet) * 12)) + parseInt(userObject.heightInches)) * 2.54;

        if (heightCentimeters > 200) heightCentimeters = 200;

        let weightKilograms = getCurrentWeight()[1] === 'kg'
            ? parseFloat(getCurrentWeight()[0])
            : (getCurrentWeight()[0] / 2.205);

        if (weightKilograms > 200) weightKilograms = 200;

        let BMR;

        if (userObject.sex === 'male') {
            BMR = 10 * weightKilograms + 6.25 * heightCentimeters - 5 * userObject.age + 5;
        } else {
            BMR = 10 * weightKilograms + 6.25 * heightCentimeters - 5 * userObject.age - 161;
        }

        let bodyFat = getCurrentBodyFat()
        if (bodyFat !== '' && !isNaN(bodyFat)) {
            let LBM = weightKilograms * (1 - getCurrentBodyFat() / 100);
            BMR = 370 + (21.6 * LBM);
        }

        let TDEE = Math.round(BMR * (((parseFloat(userObject.activityMultiplier) + parseFloat(userObject.exerciseMultiplier)) - 1)) * userObject.goalMultiplier);

        const macroBreakdown = userObject.macroBreakdown;
        let targetProtein = Math.round((TDEE * macroBreakdown.protein) / 4);
        let targetCarbs = Math.round((TDEE * macroBreakdown.carbs) / 4);
        let targetFat = Math.round((TDEE * macroBreakdown.fat) / 9);

        setUserObject({
            ...userObject,
            isUsingMacros: true,
            calories: TDEE,
            proteins: targetProtein,
            carbs: targetCarbs,
            fats: targetFat
        });

        props.handleExitClick();
    };

    return (
        <div className="calorie-calculator-form">
            <form onSubmit={handleSubmit}>
                {step === 1 && (
                    <>
                        <div className="calorie-calculator-form-line-item">
                            <FormControl>
                                <InputLabel id="gender-label">Gender</InputLabel>
                                <Select labelId="gender-label" name='sex' value={userObject.sex} onChange={handleChange}>
                                    {genderList.map((gender) => (
                                        <MenuItem key={gender.value} value={gender.value}>{gender.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>

                        <div className="calorie-calculator-form-line-item-text">
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                label="Age"
                                type="number"
                                variant="outlined"
                                name='age'
                                value={userObject.age}
                                onChange={handleChange}
                                error={ageError}
                                helperText={ageError ? "You must be at least 18 years old." : ""}
                            />
                        </div>

                        <div className="calorie-calculator-form-line-item-text">
                            {userObject.heightUnit === 'cm' ? (
                                <TextField
                                    label="Height"
                                    type="number"
                                    variant="outlined"
                                    name='height'
                                    InputLabelProps={{ shrink: true }}
                                    value={userObject.height}
                                    onChange={handleChange}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Select
                                                    name="heightUnit"
                                                    value={userObject.heightUnit}
                                                    onChange={handleChange}
                                                    disableUnderline
                                                >
                                                    <MenuItem value="cm">cm</MenuItem>
                                                    <MenuItem value="inches">inches</MenuItem>
                                                </Select>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            ) : (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <TextField
                                        label="Height (ft)"
                                        type="number"
                                        variant="outlined"
                                        name='heightFeet'
                                        InputLabelProps={{ shrink: true }}
                                        value={userObject.heightFeet}
                                        onChange={handleChange}
                                    />
                                    <TextField
                                        label="Height (in)"
                                        type="number"
                                        variant="outlined"
                                        name='heightInches'
                                        InputLabelProps={{ shrink: true }}
                                        value={userObject.heightInches}
                                        onChange={handleChange}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Select
                                                        name="heightUnit"
                                                        value={userObject.heightUnit}
                                                        onChange={handleChange}
                                                        disableUnderline
                                                    >
                                                        <MenuItem value="cm">cm</MenuItem>
                                                        <MenuItem value="inches">inches</MenuItem>
                                                    </Select>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </div>
                            )}
                        </div>

                        <div className="calorie-calculator-form-line-item-text">
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                label="Weight"
                                type="number"
                                variant="outlined"
                                name='weight'
                                value={getCurrentWeight()[0]}
                                onChange={handleWeightChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Select
                                                name="weightUnit"
                                                value={weightUnit}
                                                onChange={handleWeightUnitChange}
                                                disableUnderline
                                            >
                                                <MenuItem value="lbs">lbs</MenuItem>
                                                <MenuItem value="kg">kg</MenuItem>
                                            </Select>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <TextField
                                style={{ marginTop: '20px' }}
                                InputLabelProps={{ shrink: true }}
                                label="Body Fat % (optional)"
                                type="number"
                                variant="outlined"
                                name='bodyFatPercentage'
                                value={getCurrentBodyFat()}
                                onChange={handleBodyFatChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Select
                                                name="bodyFatUnit"
                                                value="%"
                                                disableUnderline
                                            >
                                                <MenuItem value="%">%</MenuItem>
                                            </Select>
                                        </InputAdornment>
                                    )
                                }}
                            />

                        </div>
                        {props.skip &&
                            <div style={{ fontSize: '11px', textAlign: 'center', color: 'grey', width: '315px', margin: '15px auto -3px auto' }}>By clicking next/skip you confirm you are  18 or older</div>
                        }
                        <div className="calorie-calculator-form-buttons">
                            {props.skip && (
                                <>

                                    <div onClick={props.skip} style={{ color: '#999999', fontSize: '14px', textAlign: 'center', fontFamily: 'Montserrat-SemiBold', marginLeft: 'auto' }}>
                                        <span onClick={props.skip}>SKIP</span>
                                    </div>
                                    <div style={{ backgroundColor: '#259B36', opacity: '0.5', margin: '0px 0px 0px 15px' }} className="calorie-chart-green-dot" />
                                    <div style={{ backgroundColor: '#259B36', opacity: '0.65', margin: '0px 3px 0px 3px' }} className="calorie-chart-green-dot" />
                                    <div style={{ backgroundColor: '#259B36', opacity: '0.8', margin: '0px 10px 0px 0px' }} className="calorie-chart-green-dot" />
                                </>
                            )}
                            <button
                                className={isStep1Valid() ? 'submit-button' : 'disabled-button'}
                                variant="contained"
                                style={{ marginLeft: props.skip ? "0px" : 'auto' }}
                                color="primary"
                                onClick={() => isStep1Valid() && setStep(2)}
                                disabled={!isStep1Valid()}
                            >
                                Next
                            </button>
                        </div>
                    </>
                )}

                {step === 2 && (
                    <>
                        <div style={{ marginTop: '10px' }} className="calorie-calculator-form-line-item">
                            <FormControl>
                                <InputLabel id="goal-label">Goal * </InputLabel>
                                <Select labelId="goal-label" name='goalMultiplier' value={userObject.goalMultiplier} onChange={handleChange}>
                                    {goalMultiplierList.map((goal) => (
                                        <MenuItem key={goal.value} value={goal.value}>{goal.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>

                        <div className="calorie-calculator-form-line-item">
                            <FormControl>
                                <InputLabel id="exercise-label">Exercise Activity *</InputLabel>
                                <Select labelId="exercise-label" name='exerciseMultiplier' value={userObject.exerciseMultiplier} onChange={handleChange}>
                                    {exerciseMultiplierList.map((exercise) => (
                                        <MenuItem key={exercise.value} value={exercise.value}>{exercise.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl>
                                <InputLabel id="activity-label">Daily Lifestyle *</InputLabel>
                                <Select labelId="activity-label" name='activityMultiplier' value={userObject.activityMultiplier} onChange={handleChange}>
                                    {activityMultiplierList.map((activity) => (
                                        <MenuItem key={activity.value} value={activity.value}>{activity.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                        </div>

                        <div className="calorie-calculator-form-line-item">
                            <FormControl>
                                <InputLabel id="macro-label">Macro Breakdown *</InputLabel>
                                <Select labelId="macro-label" name='macroBreakdownName' value={userObject.macroBreakdownName} onChange={handleChange}>
                                    {macroBreakdownList.map((macro) => (
                                        <MenuItem key={macro.name} value={macro.name}>{macro.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div style={{ fontSize: '11px', textAlign: 'center', color: 'grey', width: '315px', margin: '15px auto 0px auto' }}>Our calculator provides calorie/macro estimates but is not medical advice. Individual results may vary.</div>

                        <div style={{ marginTop: '24px' }} className="calorie-calculator-form-buttons">
                            <button className="cancel-button" variant="contained" color="primary" onClick={() => setStep(1)}>Back</button>
                            <button className={isStep2Valid() ? 'submit-button' : 'disabled-button'} color="primary" type="submit" disabled={!isStep2Valid()}>Get Macros</button>
                        </div>
                    </>
                )}
            </form>
        </div>
    );
};

export default CalorieCalculator;
