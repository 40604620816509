// Prod api url configs
const prod = {
    API_URL: "https://the-bite-tracker-wolnl6dghq-ue.a.run.app/",
    stripePublicKey: 'pk_live_51IoAQoB66OL4VGdL1nHXcY0rsPDdH6ivkqzY2xB99l8zGjaBqSnL1cMzsWUZy3k5MRAURCaofogVGlOaLG4mMm8G009jO8S3rk',
    emailServiceId: 'service_lv2bh3j',
    emailTemplateId: 'template_tlyl605',
    emailPublicKey: '0CLpu8GoTJPrev6XG',
    firebaseApiKey: "AIzaSyDvK6yurPEyxEDn1pqOuVug_dZSQtz7YHE",
    firebaseAuthDomain: "auth.thebitetracker.com",
    firebaseDatabaseURL: "https://z-com-thymeless-default-rtdb.firebaseio.com",
    firebaseProjectId: "z-com-thymeless",
    firebaseStorageBucket: "z-com-thymeless.appspot.com",
    firebaseMessagingSenderId: "325126069068",
    firebaseAppId: "1:325126069068:web:d0467f2fab1b98c766cde9",
    firebaseMeasurementId: "G-C4PQSMHLRG",
    emptyRecipe: {
        type: "user-made-recipe", name: "", description: "", tags: [], recipeBooks: [], areNutrientsManual: false, complexity: '', portion: 1,
        categories: [], ingredients: [], picture: "https://storage.googleapis.com/z-com-299512.appspot.com/App/custom-meal-icon-cropped.png",
        nutrition: {
            nutrients: [
                {
                    "name": "Calories",
                    "amount": 0,
                    "unit": "kcal"
                },
                {
                    "name": "Fat",
                    "amount": 0,
                    "unit": "g"
                },
                {
                    "name": "Saturated Fat",
                    "amount": 0,
                    "unit": "g"
                },
                {
                    "name": "Carbohydrates",
                    "amount": 0,
                    "unit": "g"
                },
                {
                    "name": "Calcium",
                    "amount": 0,
                    "unit": "mg"

                },
                {
                    "name": "Sugar",
                    "amount": 0,
                    "unit": "g"
                },
                {
                    "name": "Cholesterol",
                    "amount": 0,
                    "unit": "mg"
                },
                {
                    "name": "Sodium",
                    "amount": 0,
                    "unit": "mg"
                },
                {
                    "name": "Protein",
                    "amount": 0,
                    "unit": "g"
                },
                {
                    "name": "Fiber",
                    "amount": 0,
                    "unit": "g"
                },
            ],

        },
        analyzedInstructions: { steps: [] }
    },
    emptyUserObject: {
        calories: "", proteins: "", fats: "", carbs: "", isUsingMacros: false, generateDays: 3, calendarPosition: 30, generateStartingPositionX: 0,
        foodVendor: "", foodVendorID: null, foodVendorDeliveryRate: "", calendarFirstDate: new Date().toString(), windowWidth: window.innerWidth, chosenDeliveryDate: null, likedMeals: {},
        recipes: [], loaded: false, cart: [], recipeBooks: ['3729f4f9-be62-42a9-8e0e-8fa1488c3252'], macroBreakdown: { 'protein': { 'breakfast': 30, 'lunch': 30, 'dinner': 30, 'snack': 10 }, 'carbs': { 'breakfast': 30, 'lunch': 30, 'dinner': 30, snack: 10 }, 'fat': { 'breakfast': 30, 'lunch': 30, 'dinner': 30, snack: 10 } },
        sex: 'female', activityMultiplier: 1, goalMultiplier: 1, name: '', weight: {}, dietaryRestrictions: []
    },
    emptyRecipeBook: {
        name: '', recipeIds: [], picture: 'https://storage.googleapis.com/z-com-299512.appspot.com/App/recipe-book.jpg'
    },
    basePictureURL: 'https://storage.googleapis.com/z-com-299512.appspot.com/App/custom-meal-icon-cropped.png',
    baseRecipeBookId: '3729f4f9-be62-42a9-8e0e-8fa1488c3252',
    dietaryRestrictionList: [
        'low-carb',
        'vegetarian',
        'vegan',
        'gluten free',
        'dairy free'

    ]
}

// Dev api url config
const dev = {
    API_URL: "http://localhost:8080",
    stripePublicKey: 'pk_test_51IoAQoB66OL4VGdL7MpPOMnczOvyxhC8jFJqz8FfhXaIyEyS4nv2LkBaYdV298Q2csDOSSRxMRK9sRZ1dsBdGbh100lHSeaOxz',
    emailServiceId: 'service_lv2bh3j',
    emailTemplateId: 'template_tlyl605',
    emailPublicKey: '0CLpu8GoTJPrev6XG',
    firebaseApiKey: "AIzaSyDvK6yurPEyxEDn1pqOuVug_dZSQtz7YHE",
    firebaseAuthDomain: "auth.thebitetracker.com",
    firebaseDatabaseURL: "https://z-com-thymeless-default-rtdb.firebaseio.com",
    firebaseProjectId: "z-com-thymeless",
    firebaseStorageBucket: "z-com-thymeless.appspot.com",
    firebaseMessagingSenderId: "325126069068",
    firebaseAppId: "1:325126069068:web:d0467f2fab1b98c766cde9",
    firebaseMeasurementId: "G-C4PQSMHLRG",
    emptyRecipe: {
        type: "user-made-recipe", name: "", description: "", tags: [], recipeBooks: [], areNutrientsManual: false, complexity: '', portion: 1,
        categories: [], ingredients: [], picture: "https://storage.googleapis.com/z-com-299512.appspot.com/App/custom-meal-icon-cropped.png",
        nutrition: {
            nutrients: [
                {
                    "name": "Calories",
                    "amount": 0,
                    "unit": "kcal"
                },
                {
                    "name": "Fat",
                    "amount": 0,
                    "unit": "g"
                },
                {
                    "name": "Saturated Fat",
                    "amount": 0,
                    "unit": "g"
                },
                {
                    "name": "Carbohydrates",
                    "amount": 0,
                    "unit": "g"
                },
                {
                    "name": "Calcium",
                    "amount": 0,
                    "unit": "mg"

                },
                {
                    "name": "Sugar",
                    "amount": 0,
                    "unit": "g"
                },
                {
                    "name": "Cholesterol",
                    "amount": 0,
                    "unit": "mg"
                },
                {
                    "name": "Sodium",
                    "amount": 0,
                    "unit": "mg"
                },
                {
                    "name": "Protein",
                    "amount": 0,
                    "unit": "g"
                },
                {
                    "name": "Fiber",
                    "amount": 0,
                    "unit": "g"
                },
            ],
        },
        analyzedInstructions: { steps: [] }
    },
    emptyRecipeBook: {
        name: '', recipeIds: [], picture: 'https://storage.googleapis.com/z-com-299512.appspot.com/App/recipe-book.jpg'
    },
    emptyUserObject: {
        calories: "", proteins: "", fats: "", carbs: "", isUsingMacros: false, generateDays: 3, calendarPosition: 30, generateStartingPositionX: 0,
        foodVendor: "", foodVendorID: null, foodVendorDeliveryRate: "", calendarFirstDate: new Date().toString(), windowWidth: window.innerWidth, chosenDeliveryDate: null, likedMeals: {},
        recipes: [], loaded: false, cart: [], recipeBooks: ['3729f4f9-be62-42a9-8e0e-8fa1488c3252'], macroBreakdown: { 'protein': { 'breakfast': 30, 'lunch': 30, 'dinner': 30, 'snack': 10 }, 'carbs': { 'breakfast': 30, 'lunch': 30, 'dinner': 30, snack: 10 }, 'fat': { 'breakfast': 30, 'lunch': 30, 'dinner': 30, snack: 10 } },
        sex: 'female', activityMultiplier: 1, goalMultiplier: 1, name: '', weight: {}, dietaryRestrictions: []
    },
    basePictureURL: 'https://storage.googleapis.com/z-com-299512.appspot.com/App/custom-meal-icon-cropped.png',
    baseRecipeBookId: '3729f4f9-be62-42a9-8e0e-8fa1488c3252',
    dietaryRestrictionList: [
        'low-carb',
        'vegetarian',
        'vegan',
        'gluten free',
        'dairy free'
    ]
}

export const config = process.env.NODE_ENV === "development" ? dev : prod;