
import './calorie_chart.css'

import React, { useState } from "react";
import { getMealNutrientAmount } from "helpers/recipe_helpers";
import { ProgressBar } from "react-bootstrap";
import _ from 'lodash';
import { NarrowTextField } from '../reusables/narrow_text_field';


const CalorieChart = (props) => {
    const [errorMessage, setErrorMessage] = useState("");  // State to manage error messages

    let totalCarbs = getMealNutrientAmount(props.meal, "Carbohydrates");
    let totalProteins = getMealNutrientAmount(props.meal, "Protein");
    let totalFats = getMealNutrientAmount(props.meal, "Fat");
    let totalCalories = getMealNutrientAmount(props.meal, "Calories");
    let totalSodium = getMealNutrientAmount(props.meal, "Sodium");
    let totalSugar = getMealNutrientAmount(props.meal, "Sugar");
    let totalCholesterol = getMealNutrientAmount(props.meal, "Cholesterol");
    let totalFiber = getMealNutrientAmount(props.meal, "Fiber");
    let totalSaturatedFat = getMealNutrientAmount(props.meal, "Saturated Fat");
    let totalNetCarbs = getMealNutrientAmount(props.meal, "Net Carbohydrates");

    // Default nutrient values if empty
    totalCarbs = totalCarbs === "" ? 0 : totalCarbs;
    totalProteins = totalProteins === "" ? 0 : totalProteins;
    totalFats = totalFats === "" ? 0 : totalFats;
    totalCalories = totalCalories === "" ? 0 : totalCalories;
    totalSodium = totalSodium === "" ? 0 : totalSodium;
    totalSugar = totalSugar === "" ? 0 : totalSugar;
    totalCholesterol = totalCholesterol === "" ? 0 : totalCholesterol;
    totalSaturatedFat = totalSaturatedFat === "" ? 0 : totalSaturatedFat;
    totalFiber = totalFiber === "" ? 0 : totalFiber;
    totalNetCarbs = totalNetCarbs === "" ? 0 : totalNetCarbs;

    let percentProteins = isNaN(Math.round(100 * 4 * totalProteins / (totalCarbs * 4 + totalProteins * 4 + 9 * totalFats))) ? 0 : Math.round(100 * 4 * totalProteins / (4 * totalCarbs + 4 * totalProteins + 9 * totalFats));
    let percentFats = isNaN(Math.round(100 * 9 * totalFats / (totalCarbs * 4 + totalProteins * 4 + 9 * totalFats))) ? 0 : Math.round(100 * 9 * totalFats / (4 * totalCarbs + 4 * totalProteins + 9 * totalFats));
    let percentCarbs = isNaN(Math.round(100 * 4 * totalCarbs / (totalCarbs * 4 + totalProteins * 4 + 9 * totalFats))) ? 0 : Math.round(100 * 4 * totalCarbs / (4 * totalCarbs + 4 * totalProteins + 9 * totalFats));

    const handleNutrientChange = (e) => {
        if (e.target.value < 0) { return; }
        if (e.target.name === "Fiber" && parseFloat(e.target.value) > totalCarbs) {
            setErrorMessage("Fiber amount cannot be higher than carbohydrates");
            return;
        } else {
            setErrorMessage("");  // Clear error message if input is valid
        }

        let newRecipe = _.cloneDeep(props.meal);
        for (let recipeNutrient of newRecipe.nutrition.nutrients) {
            if (recipeNutrient.name === e.target.name) {
                console.log(recipeNutrient.name)
                recipeNutrient['amount'] = e.target.value;
            }
        }

        if (props.setStagedChanges) {
            props.setStagedChanges(true);
        }
        props.setRecipe(newRecipe);
    }

    if (props.meal.areNutrientsManual) {
        return (

            <div style={{ paddingBottom: '20px', margin: '0px 10px' }}>
                <div className="calorie-chart-top-info">  <div className="calorie-chart-top-info-label">Protein</div>   <div className="calorie-chart-top-info-right">
                    <NarrowTextField className="modify-recipe-nutrient-textfield" name="Protein" type="number" value={totalProteins} onChange={handleNutrientChange} inputProps={{ readOnly: props.isReadOnly }}></NarrowTextField>g  <div className="calorie-chart-green-dot"></div>
                    <div className="calorie-chart-top-info-percent">{percentProteins}%</div></div> </div>
                <ProgressBar className="calorie-chart-protein" now={percentProteins} key={1} max={100} />

                <div className="calorie-chart-top-info">  <div className="calorie-chart-top-info-label">Carbs</div>   <div className="calorie-chart-top-info-right">
                    <NarrowTextField className="modify-recipe-nutrient-textfield" name="Carbohydrates" type="number" value={totalCarbs} onChange={handleNutrientChange} inputProps={{ readOnly: props.isReadOnly }}></NarrowTextField>g<div className="calorie-chart-green-dot"></div>
                    <div className="calorie-chart-top-info-percent">{percentCarbs}%</div></div> </div>
                <ProgressBar className="calorie-chart-carbs" now={percentCarbs} key={2} max={100} />

                <div className="calorie-chart-top-info">  <div className="calorie-chart-top-info-label">Fat</div>   <div className="calorie-chart-top-info-right">
                    <NarrowTextField className="modify-recipe-nutrient-textfield" name="Fat" type="number" value={totalFats} onChange={handleNutrientChange} inputProps={{ readOnly: props.isReadOnly }}></NarrowTextField>g
                    <div className="calorie-chart-green-dot"></div>
                    <div className="calorie-chart-top-info-percent">{percentFats}%</div></div> </div>
                <ProgressBar className="calorie-chart-fats" now={percentFats} key={3} max={100} />

                <div className="calorie-chart-top-info">  <div className="calorie-chart-top-info-label">Calories</div>   <div className="calorie-chart-top-info-right">
                    <NarrowTextField className="modify-recipe-nutrient-textfield" name="Calories" type="number" value={totalCalories} onChange={handleNutrientChange} readOnly={props.isReadOnly}></NarrowTextField>Cals </div></div>
                <ProgressBar className="calorie-chart-calories" now={100} key={4} max={100} />

                <div className="calorie-chart-top-info">  <div className="calorie-chart-top-info-label">Sodium</div>   <div className="calorie-chart-top-info-right">
                    <NarrowTextField className="modify-recipe-nutrient-textfield" name="Sodium" type="number" value={totalSodium} onChange={handleNutrientChange} readOnly={props.isReadOnly}></NarrowTextField>mg </div></div>
                <ProgressBar className="calorie-chart-sodium" now={100} key={5} max={100} />

                <div className="calorie-chart-top-info">  <div className="calorie-chart-top-info-label">Sugar</div>   <div className="calorie-chart-top-info-right">
                    <NarrowTextField className="modify-recipe-nutrient-textfield" name="Sugar" type="number" value={totalSugar} onChange={handleNutrientChange} readOnly={props.isReadOnly}></NarrowTextField>g </div></div>
                <ProgressBar className="calorie-chart-sugar" now={100} key={6} max={100} />

                <div className="calorie-chart-top-info">  <div className="calorie-chart-top-info-label">Cholesterol</div>   <div className="calorie-chart-top-info-right">
                    <NarrowTextField className="modify-recipe-nutrient-textfield" name="Cholesterol" type="number" value={totalCholesterol} onChange={handleNutrientChange} readOnly={props.isReadOnly}></NarrowTextField>mg </div></div>
                <ProgressBar className="calorie-chart-cholesterol" now={100} key={7} max={100} />

                <div className="calorie-chart-top-info">  <div className="calorie-chart-top-info-label">Fiber</div>   <div className="calorie-chart-top-info-right">
                    <NarrowTextField className="modify-recipe-nutrient-textfield" name="Fiber" type="number" value={totalFiber} onChange={handleNutrientChange} readOnly={props.isReadOnly}></NarrowTextField>g </div></div>
                <ProgressBar className="calorie-chart-fiber" now={100} key={8} max={100} />
                {errorMessage && <div style={{color:'red', fontSize:'12px', marginTop:'10px'}}>{errorMessage}</div>} {/* Display error message */}

                <div className="calorie-chart-top-info">  <div className="calorie-chart-top-info-label">Net Carbs</div>   <div className="calorie-chart-top-info-right">
                    <NarrowTextField className="modify-recipe-nutrient-textfield" name="Net Carbs" type="number" value={totalNetCarbs} onChange={handleNutrientChange} readOnly={true} ></NarrowTextField>g </div></div>
                <ProgressBar className="calorie-chart-net-carbs" now={100} key={9} max={100} />

                <div className="calorie-chart-top-info">  <div className="calorie-chart-top-info-label">Saturated Fat</div>   <div className="calorie-chart-top-info-right">
                    <NarrowTextField className="modify-recipe-nutrient-textfield" name="Saturated Fat" type="number" value={totalSaturatedFat} onChange={handleNutrientChange} readOnly={props.isReadOnly}></NarrowTextField>g </div></div>
                <ProgressBar className="calorie-chart-saturated-fat" now={100} key={10} max={100} />
            </div>

        )
    }

    else {

        return (
            <div style={{ margin: '0px 10px' }}>
                <div className="calorie-chart-top-info">  <div className="calorie-chart-top-info-label">Protein</div>   <div className="calorie-chart-top-info-right">
                    <div className="calorie-chart-top-info-grams">{totalProteins}g</div> <div className="calorie-chart-green-dot"></div>
                    <div className="calorie-chart-top-info-percent">{percentProteins}%</div></div> </div>
                <ProgressBar className="calorie-chart-protein" now={percentProteins} key={1} max={100} />
                <div className="calorie-chart-top-info">  <div className="calorie-chart-top-info-label">Carbs</div>   <div className="calorie-chart-top-info-right">
                    <div className="calorie-chart-top-info-grams">{totalCarbs}g</div> <div className="calorie-chart-green-dot"></div>
                    <div className="calorie-chart-top-info-percent">{percentCarbs}%</div></div> </div>
                <ProgressBar className="calorie-chart-carbs" now={percentCarbs} key={2} max={100} />
                <div className="calorie-chart-top-info">  <div className="calorie-chart-top-info-label">Fat</div>   <div className="calorie-chart-top-info-right">
                    <div className="calorie-chart-top-info-grams">{totalFats}g</div> <div className="calorie-chart-green-dot"></div>
                    <div className="calorie-chart-top-info-percent">{percentFats}%</div></div> </div>
                <ProgressBar className="calorie-chart-fats" now={percentFats} key={3} max={100} />

                <div className="calorie-chart-top-info">  <div className="calorie-chart-top-info-label">Calories</div>   <div className="calorie-chart-top-info-right">
                    <div className="calorie-chart-top-info-grams">{totalCalories} Cals</div> </div></div>
                <ProgressBar className="calorie-chart-calories" now={100} key={4} max={100} />

                <div className="calorie-chart-top-info">  <div className="calorie-chart-top-info-label">Sodium</div>   <div className="calorie-chart-top-info-right">
                    <div className="calorie-chart-top-info-grams">{totalSodium} mg</div> </div></div>
                <ProgressBar className="calorie-chart-sodium" now={100} key={5} max={100} />

                <div className="calorie-chart-top-info">  <div className="calorie-chart-top-info-label">Sugar</div>   <div className="calorie-chart-top-info-right">
                    <div className="calorie-chart-top-info-grams">{totalSugar} g</div> </div></div>
                <ProgressBar className="calorie-chart-sugar" now={100} key={6} max={100} />

              
                <div className="calorie-chart-top-info">  <div className="calorie-chart-top-info-label">Fiber</div>   <div className="calorie-chart-top-info-right">
                <div className="calorie-chart-top-info-grams">{totalFiber} g</div> </div></div>
                <ProgressBar className="calorie-chart-fiber" now={100} key={8} max={100} />

                <div className="calorie-chart-top-info">  <div className="calorie-chart-top-info-label">Net Carbs</div>   <div className="calorie-chart-top-info-right">
                <div className="calorie-chart-top-info-grams">{totalNetCarbs} g</div> </div></div>
                <ProgressBar className="calorie-chart-net-carbs" now={100} key={9} max={100} />

                <div className="calorie-chart-top-info">  <div className="calorie-chart-top-info-label">Saturated Fat</div>   <div className="calorie-chart-top-info-right">
                <div className="calorie-chart-top-info-grams">{totalSaturatedFat} g</div> </div></div>
                <ProgressBar className="calorie-chart-saturated-fat" now={100} key={10} max={100} />
            </div>
        )
    }
}

export default CalorieChart;